$(document).on("turbolinks:load", function () {
  let campaignSelect = $("#campaign_id"),
    campaignWeekSelect = $("#campaign_start_date");

  $("#apply_filters_btn").on("click", function () {
    submitSearch();
  });

  let submitSearch = function () {
    $("#hidden_spinner").show();
    $.ajax({
      url: "/dashboards/campaigns.js",
      type: "GET",
      data: {campaign_id: campaignSelect.val()},
      complete: function () {
        $("#hidden_spinner").hide();
      }
    });
  };

  // bind the javascript events to elements which will always be present in the DOM, and delegate from them
  $(document).on("click", "#select_week_btn", function () {
    selectedWeek = $("#campaign_start_date").val()
    submitCampaingWeek(selectedWeek);
  });

  let submitCampaingWeek = function (selectedWeek) {
    $("#chart_hidden_spinner").show();
    $.ajax({
      url: "/dashboards/campaigns/"+ campaignSelect.val() + ".js",
      type: "GET",
      data: {campaign_id: campaignSelect.val(), campaign_start_date: selectedWeek},
      complete: function () {
        $("#chart_hidden_spinner").hide();
      }
    });
  };
});
