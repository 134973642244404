$(document).on("turbolinks:load", function () {
    $(document).ready(function () {
        $("#select2-discount-search").select2({
            placeholder: "Search",
            minimumInputLength: 3,
            ajax: {
                url: "/shopify_discount_codes.json",
                dataType: "json",
                delay: 250,
                quietMillis: 300,
                data: function (params) {
                    return { search: params.term }
                }
            }
        });
    });
});
