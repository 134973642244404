$(document).on("turbolinks:load", function () {
  let skuTypeSelect = $(".js-subscription-sku-type");
  
  skuTypeSelect.each(function (e) {
    displaySkuOption($(skuTypeSelect[e]));
  });

  skuTypeSelect.on("change", function (e) {
    displaySkuOption($(e.target));
  });

  function displaySkuOption (select) {
      let skuFields = select.siblings(".js-subscription-sku");
      if (select.val() === "CB") {
          skuFields.show();
      } else {
          skuFields.hide();
      }
  };
});
