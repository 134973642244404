$(document).on('turbolinks:load', function () {
  $(document).ready(function () {
    let productBoxParent = $("#system-product-box"),
      productDropInProductParent = $("#system-drop-in-product-box");

    $(".search_variant_for_system").select2({
      matcher: matchCustom
    });

    $("#system_system_box_shipped_separately").on("change", function (e) {
      let value = e.target.checked;

      if (value === true) {
        productBoxParent.show();
        productDropInProductParent.hide();
      } else {
        productBoxParent.hide();
        productDropInProductParent.show();
      }
    });
  });
});

function matchCustom(params, data) {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === '') {
    return data;
  }

  if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
    return $.extend({}, data, true);
  }

  // Return `null` if the term should not be displayed
  return null;
}
