$( document ).on("turbolinks:load", function() {
  $("#campaign_id").on("change", function(event){
    let sub_id = this.getAttribute("data-subscription-id");
    $.ajax({
      url : "/campaigns/"+ event.target.value,
      type: "GET",
      dataType: "json",
      data: {filter_by_subscription: sub_id},
      success : function(data) {
        let select_start_week = $('#start_week');
        let select_detox_plus_week = $('#opt_in_detox_week');
        let sold_out_div = $("#signature_week_sold_out_message");

        sold_out_div.hide();

        if(data.sold_out_start_weeks[0]) {
          sold_out_div.show();
        }

        select_start_week.empty();
        select_detox_plus_week.empty();

        if (data.locked_in) {
          let opt = $('<option/>');
          opt.attr("value", data.start_weeks[0]);
          opt.attr("data-sold-out", data.sold_out_start_weeks.includes(data.start_weeks[0]));
          opt.text(data.start_weeks[0]);
          opt.appendTo(select_start_week);
        } else {
          $.each(data.start_weeks, function(index, value) {
            let opt = $('<option/>');
            opt.attr("value", value);
            opt.attr("data-sold-out", data.sold_out_start_weeks.includes(value));
            opt.text(value);
            opt.appendTo(select_start_week);
          });
        }

        select_detox_plus_week.prepend("<option value=''>No Level II Detox</option>");
        let selected_start_week = $('#start_week').find(":selected").val()

        $.each(data.detox_plus_weeks, function(index, value) {
          let opt = $('<option/>');
          opt.attr("value", value);
          opt.attr("data-sold-out", data.sold_out_detox_plus_weeks.includes(value));
          opt.text(value);
          if(value <= selected_start_week) {
            opt.attr('disabled', 'disabled');
          }
          opt.appendTo(select_detox_plus_week);
        });
      }
    })
  });

  $("#opt_in_detox_week").on("change", function(event) {
    $("#opt_in_detox_sold_out_message").hide()
    let sold_out = $('option:selected', this).attr("data-sold-out");

    if(sold_out === "true") {
     $("#opt_in_detox_sold_out_message").show()
    }
  });

  $("#update_detox_week").on("change", function(event) {
    $("#detox_week_sold_out_message").hide()
    let sold_out = $('option:selected', this).attr("data-sold-out");

    if(sold_out === "true") {
     $("#detox_week_sold_out_message").show()
    }
  });

  $("#start_week").on("change", function(event) {
    $("#signature_week_sold_out_message").hide()
    let sold_out = $('option:selected', this).attr("data-sold-out");
    let start_week = $('option:selected', this).val();

    if(sold_out === "true") {
     $("#signature_week_sold_out_message").show()
    }

    $('#opt_in_detox_week option').each(function () {
      if($(this).val() != '' && $(this).val() <= start_week) {
        $(this).attr('disabled', 'disabled');
      }else{
        $(this).removeAttr('disabled');
      }
    });
  });
});
