$(document).on("turbolinks:load", function () {
    let bgColorInput = $("#delivery_zone_notice_bg_colour"), textColourInput = $("#delivery_zone_notice_text_colour");
    $('#display-bg-color').css('background-color', bgColorInput.val());
    $('#display-text-color').css('background-color', textColourInput.val());

    bgColorInput.on("change", function () {
        $('#display-bg-color').css('background-color', bgColorInput.val());
    });
    textColourInput.on("change", function () {
        $('#display-text-color').css('background-color', textColourInput.val());
    });
});
