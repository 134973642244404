import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ["add_item", "template"]

    add_association(event) {
        event.preventDefault()
        const current_time_stamp = new Date().getTime()
        const content = this.templateTarget.innerHTML.replace(/DROP_IN_TEMPLATE_RECORD/g, current_time_stamp);
        this.add_itemTarget.insertAdjacentHTML('beforebegin', content);

        const program_length = $("#campaign_program_length").val()
        if(program_length > 1) {
            let select_input = $("#campaign_campaign_drop_ins_attributes_"+current_time_stamp+"_week_number")
            select_input.empty();
            for(let n = 1; n <= program_length; n++) {
                select_input.append($("<option></option>")
                    .attr("value", n).text(n))
            }
        }
    }

    remove_association(event) {
        event.preventDefault()
        let nestedFormClass = '.'+event.currentTarget.dataset.nestedFormClass
        let item = event.target.closest(nestedFormClass)
        item.querySelector("input[name*='_destroy']").value = 1
        item.style.display = 'none'
    }
}
