$(document).on("turbolinks:load", function () {
  let campaignSelect = $("#campaign_id");

  $("#campaign_filter_btn").on("click", function () {
    submitSearch();
  });

  let submitSearch = function () {
    $("#hidden_spinner").show();
    $.ajax({
      url: "/dashboards/campaign_goals.js",
      type: "GET",
      data: {campaign_id: campaignSelect.val()},
      complete: function () {
        $("#hidden_spinner").hide();
      }
    });
  };
});
