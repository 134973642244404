$(document).on("turbolinks:load", function () {
    $('input[type="radio"][name="discount[rule]"]').change(function() {
        if (this.value == 'code') {
            $('.show-manual-discount').addClass('form-group--inactive');
            $('.show-discount-code').removeClass('form-group--inactive');
        }
        else if (this.value == 'manual') {
            $('.show-manual-discount').removeClass('form-group--inactive');
            $('.show-discount-code').addClass('form-group--inactive');
        }
    });
    $('input[type="radio"][name="discount[type]"]').change(function() {
        if (this.value == 'percentage') {
            $('.show-fix-amount-prepend').addClass('text-input-group--inactive');
            $('.show-percentage-append').removeClass('text-input-group--inactive');
        }
        else if (this.value == 'fixed_amount') {
            $('.show-fix-amount-prepend').removeClass('text-input-group--inactive');
            $('.show-percentage-append').addClass('text-input-group--inactive');
        }
    });

    $(document).on('click', 'a.apply_discount_code', function(event) {
        event.preventDefault();
        $('#discount_code').val(this.text)
    });

    let timeout = null, searchDiscountCode = $('#discount_code')

    searchDiscountCode.on("input", function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            submitDiscountCode()
        }, 500);
    });

    let submitDiscountCode = function () {
      $.ajax({
        url: "/subscriptions.js",
        type: "GET",
        data: {discount_code: searchDiscountCode.val()},
      });
    };

});
