function disable_element(e, val) {
    if (val) {
        e.val(val);
    }

    e.children('option').each(function () {
        let visible = ($(this).val() == val);
        if (visible) {
            $(this).show();
        }
        else {
            $(this).hide();
        }
    });

    e.attr('readonly', true);
}

function enable_element(e) {
    e.children('option').each(function () {
        $(this).show();
    });

    e.attr('readonly', false);
}

let changed_program_fields = new Set();
let changed_dz_fields = new Set();

$(document).on("turbolinks:load", function () {
    // suffixes may be added to SKUs if a campaign is selected
    // save original value in case campaign is unselected
    $('.kms-order-line-item-sku').children('option').each(function () {
        $(this).data('original', $(this).val());
    });

    // only one option (eg. campaign or system) can be selected at a time
    $('.kms-option-field').on('change', function () {
        let line_item_form = $(this).closest('.kms-order-line-item-form');
        let option = $(this).val();
        let otherOptionFields = line_item_form.find('.kms-option-field').not(this);

        if (option) {
            otherOptionFields.each(function () {
                disable_element($(this), '');
            });
        } else {
            otherOptionFields.each(function () {
                enable_element($(this));
            });
        }
    });

    // on system change
    // disable detox week & supplement
    // restrict SKU & program options
    $('.kms-order-line-item-system').on('change', function () {
        let system = $(this).val();
        let selected_option = $(this).find(':selected');
        let line_item_form = $(this).closest('.kms-order-line-item-form');
        let detox_week_select = line_item_form.find('.kms-order-line-item-detox-plus-week');
        let supplement_select = line_item_form.find('.kms-order-line-item-supplement');
        let sku_select = line_item_form.find('.kms-order-line-item-sku');
        let program_select = line_item_form.find('.kms-order-line-item-program');

        if (system) {
            let no_addon = supplement_select.children('option[data-no-addon="true"]');
            disable_element(supplement_select, no_addon.val());
            disable_element(detox_week_select, '');

            let selected_sku = sku_select.val();
            selected_sku = selected_sku.startsWith("BLD") ? selected_sku : "BLD";
            disable_element(sku_select, selected_sku);

            let system_program = selected_option.data("program");
            let program_option_exists = program_select.find(`[value="${system_program}]`).length > 0;
            if (!program_option_exists)
            {
              program_select.append(`<option value="${system_program}">${system_program}</option>`);
            }
            disable_element(program_select, system_program);
        } else {
            enable_element(supplement_select);
            enable_element(detox_week_select);
            enable_element(sku_select);
            enable_element(program_select);
        }
    });

    // on campaign change
    // concat campaign suffix to SKU options
    // populate detox plus week options
    $('.kms-order-line-item-campaign').on('change', function () {
        let line_item_form = $(this).closest('.kms-order-line-item-form');
        let detox_week_select = line_item_form.find('.kms-order-line-item-detox-plus-week');
        let selected_detox_week = detox_week_select.val();

        let campaign_id = $(this).val();
        if (campaign_id) {
            $.ajax({
                url: `/campaigns/${campaign_id}.json`,
                type: "GET",
                success: function (campaign) {
                    detox_week_select.empty();
                    let blank = $('<option value>None</option>');
                    blank.appendTo(detox_week_select);

                    // populate detox plus week options
                    campaign.sold_out_detox_plus_weeks.forEach(function (week_tuple) {
                        let week = week_tuple[0];
                        let is_sold_out = week_tuple[1];
                        if (is_sold_out) {
                            return;
                        }

                        let opt = $('<option/>');
                        opt.attr("value", week);
                        opt.text(week);
                        opt.appendTo(detox_week_select);

                        if (selected_detox_week == week) {
                            detox_week_select.val(week);
                        }
                    });

                    // add suffix to SKU options
                    $('.kms-order-line-item-sku').children('option').each(function () {
                        let sku = $(this).data('original');
                        if (campaign.sku_suffix) {
                            sku = sku.split("_")[0];
                            sku += '_' + campaign.sku_suffix;
                        }
                        $(this).val(sku);
                        $(this).text(sku);
                    });
                }
            });
        } else {
            detox_week_select.empty();
            let blank = $('<option value>None</option>');
            blank.appendTo(detox_week_select);

            $('.kms-order-line-item-sku').children('option').each(function () {
                let sku = $(this).data('original');
                $(this).val(sku);
                $(this).text(sku);
            });
        }
    });

    $('.kms-option-field').trigger('change');

    // program fields are KmsOrderLineItem fields that require the item to be regenerated if changed
    // keep track of what they were when the page was loaded
    $('.kms-program-field').each(function () {
        $(this).data('previous', $(this).val());
    });
    // keep track of whether the value of any are changed
    $('.kms-program-field').on('change', function () {
        let field_id = $(this).attr('id');
        let old_val = $(this).data('previous');
        let new_val = $(this).val();
        if (new_val != old_val) {
            changed_program_fields.add(field_id);
        } else {
            changed_program_fields.delete(field_id);
        }
    });
    // if so, then show confirmation modal before updating item. else just update the item
    $('#kms_order_line_item_update_btn').on('click', function () {
        if (changed_program_fields.size > 0) {
            $('#kms_order_line_item_confirm_modal').modal('show');
        } else {
            $('#kms_order_line_item_update_confirm_btn').click();
        }
    });
    // if delivery zone has changed, show confirmation modal before updating address
    $('#kms_shipping_address_update_btn').on('click', function () {
        if (changed_dz_fields.size > 0) {
            $('#kms_shipping_address_confirm_modal').modal('show');
        } else {
            $('#kms_shipping_address_update_confirm_btn').click();
        }
    });

    // for orders with multiple line items, reload page with selected line item's details
    $('#kms_order_line_item_select').on("change", function (event) {
        let line_item_id = $(this).val();
        let order_id = $(this).data('order-id');
        window.location.href = `/kms_orders/${order_id}?line_item_id=${line_item_id}`;
    });

    // toggle kms export details div with caret icon
    $('.kms-export-toggle').on("click", function (event) {
        let export_div = $(this).closest('.kms-export-div');
        let json_div = export_div.find('.kms-order-json-div');
        json_div.toggle('fast');

        if ($(this).hasClass('fa-angle-down')) {
            $(this).removeClass('fa-angle-down');
            $(this).addClass('fa-angle-up');
        } else {
            $(this).removeClass('fa-angle-up');
            $(this).addClass('fa-angle-down');
        }
    });

    // copy JSON to clipboard
    $('.kms_order_copy_json').on("click", function (event) {
        let export_div = $(this).closest('.kms-export-div');
        let json_div = export_div.find('.kms-export-json');
        navigator.clipboard.writeText(json_div.text());
    });

    // get active campaign options for start date
    $('.kms-order-line-item-start-date').on("change", function (event) {
        let date = $(this).val();
        let line_item_form = $(this).closest('.kms-order-line-item-form');
        let campaign_select = line_item_form.find('.kms-order-line-item-campaign');
        let selected_campaign = campaign_select.val();
        $.ajax({
            url: `/campaigns/active_campaigns?date=${date}/`,
            type: "GET",
            success: function (campaigns) {
                campaign_select.empty();
                let blank = $('<option value>None</option>');
                blank.appendTo(campaign_select);

                if (campaigns) {
                    campaigns.forEach(campaign => {
                        let opt = $('<option/>');
                        opt.attr("value", campaign.id);
                        opt.text(campaign.name);
                        opt.appendTo(campaign_select);

                        if (selected_campaign == campaign.id) {
                            campaign_select.val(campaign.id);
                        }
                    });
                }
                $('.kms-order-line-item-campaign').trigger('change');
            }
        });
    });

    // populate kms shipping address fields from selected preset
    $(".kms-shipping-address-preset").on("change", function (event) {
        let address_id = event.target.value;
        let form = $(this).closest('.kms-shipping-address-form');
        if (address_id != "N/A") {
            $.ajax({
                url: `/shipping_addresses/${address_id}/`,
                type: "GET",
                success: function (data) {
                    let address = data.address;
                    form.find('.address1').val(address.address1);
                    form.find('.address2').val(address.address2);
                    form.find('.city').val(address.city);
                    form.find('.province').val(address.province);
                    form.find('.zip').val(address.zip);
                    form.find('.phone').val(address.phone);

                    let select_window = form.find('.delivery-window');
                    select_window.empty();
                    data.delivery_windows.forEach(window => {
                        let opt = $('<option/>');
                        opt.attr("value", window);
                        opt.text(window);
                        opt.appendTo(select_window);
                    });
                }
            });
        } else {
            $('.kms-order-address-part').val('');
        }
    });

    // set shipping address preset to N/A if any field is changed
    $('.kms-shipping-address-field').on("change", function () {
        let form = $(this).closest('.kms-shipping-address-form');
        form.find('.kms-shipping-address-preset').val("N/A");
    });

    // update delivery window options on zipcode change
    $('body').on('change', '.kms-shipping-address-form .zip', function () {
        let og_delivery_zone = $(this).data('dz-id');
        let zip_field_id = $(this).attr('id');
        let zip = $(this).val();
        let form = $(this).closest('.kms-shipping-address-form');
        let select_window = form.find('.delivery-window');
        let dz_label = form.find('.delivery-zone');
        select_window.empty();
        $.ajax({
            url: `/delivery_zones/from_zip`,
            type: "GET",
            data: {"zip_code": zip},
            success: function (data) {
                if(data.error){
                    alert (data.error + " " + data.zip_code);
                    return;
                }
                data.delivery_windows.forEach(window => {
                    let opt = $('<option/>');
                    opt.attr("value", window);
                    opt.text(window);
                    opt.appendTo(select_window);
                });
                dz_label.text(data.delivery_zone.name);
                dz_label.attr("href", `/delivery_zones/${data.delivery_zone.id}`);

                // mark whether delivery zone has changed; if so, then we'll display warning to user before updating address
                // since it will require regenerating the program
                if (og_delivery_zone) {
                    if (data.delivery_zone.id != og_delivery_zone) {
                        changed_dz_fields.add(zip_field_id);
                        dz_label.addClass("text-danger");
                    } else {
                        changed_dz_fields.delete(zip_field_id);
                        dz_label.removeClass("text-danger");
                    }
                }
            }
        });
    });
});
