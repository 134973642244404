$(document).on("turbolinks:load", function () {
    $('[data-toggle-secondary]').each(function() {
        let $toggle = $(this);
        let originalText = $toggle.text();
        let secondaryText = $toggle.data('toggle-secondary');
        let $target = $($toggle.attr('href'));

        $target.on('show.bs.collapse hidden.bs.collapse', function() {
            if ($toggle.text() == originalText) {
                $toggle.text(secondaryText);
            } else {
                $toggle.text(originalText);
            }
        });
    });
});