$(document).on("turbolinks:load", function () {
  $('.box-size-config-param-select').on('change', function () {
    let size_class = $('#size_class').val();

    let new_url = `/box_size_configurations?size_class=${size_class}`;
    window.location.href = new_url;
  });

  $('.box-size-config-select').on('change', function () {
    let og_val = $(this).data("previous");
    let new_val = $(this).val();
    $(this).css("border-color", og_val == new_val ? "" : "orange");
  });
});

