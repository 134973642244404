$(document).on("turbolinks:load", function () {
    $(document).ready(function () {
        $(".discount_code_search_select2").select2({
            placeholder: "Search",
            minimumInputLength: 3,
            ajax: {
                url: "/shopify_discount_codes.json",
                dataType: "json",
                delay: 250,
                quietMillis: 300,
                data: function (params) {
                    var query = {
                        search: params.term,
                        id_type: "code",
                    }
                    return query;
                }
            }
        });
    });
});
