import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["add_item", "template"]

  add_association(event) {
    event.preventDefault()
    let new_record_target = event.currentTarget.dataset.target
    let content = '';
    switch(new_record_target) {
      case "add-delivery-cycle":
        content  = this.templateTarget.innerHTML.replace(/CYCLE_TEMPLATE_RECORD/g, new Date().getTime())
        break;
      case "add-delivery-day":
        content  = this.templateTarget.innerHTML.replace(/DAY_TEMPLATE_RECORD/g, new Date().getTime())
        break;
      default:
    }
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
