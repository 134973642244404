$(document).on("turbolinks:load", function () {
    $("#system_id").on("change", function (event) {
        let sub_id = this.getAttribute("data-subscription-id");
        let system_id = event.target.value;
        $.ajax({
            url: `/subscriptions/${sub_id}/systems/${system_id}/`,
            type: "GET",
            dataType: "json",
            data: {subscription_id: sub_id, system_id: event.target.value},
            success: function (data) {
                let select_start_week = $('#system_start_week');
                select_start_week.empty();

                $.each(data.start_weeks, function(index, value) {
                    let opt = $('<option/>');
                    opt.attr("value", value);
                    opt.text(value);
                    opt.appendTo(select_start_week);
                });
            }
        });
    });
});