import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["add_item", "template"]

  add_association(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)

    const nestedFormClass = '.' + event.currentTarget.dataset.nestedFormClass
    let nestedFields = Array.from(document.querySelectorAll(nestedFormClass)).filter(element => element.querySelector("input[name*='_destroy']").value != 1)
    const lastNestedField = nestedFields[nestedFields.length - 2]
    let lastDate = moment().startOf('week').toDate()
    lastDate.setDate(lastDate.getDate() + 8)
    if (lastNestedField) {
      let lastDateString = lastNestedField.children[0].children[1].value
      lastDate = moment(lastDateString).add(1, 'weeks').toDate()
    }

    const year = lastDate.getFullYear()
    const month = (lastDate.getMonth() + 1).toString().padStart(2, "0")
    const day = lastDate.getDate().toString().padStart(2, "0");
    nestedFields[nestedFields.length - 1].children[0].children[1].value = `${year}-${month}-${day}`
    nestedFields[nestedFields.length - 1].id += `${year}-${month}-${day}`
    $('.checkbox').bootstrapToggle();
  }

  remove_association(event) {
    event.preventDefault()
    let nestedFormClass = '.' + event.currentTarget.dataset.nestedFormClass
    let item = event.target.closest(nestedFormClass)
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
