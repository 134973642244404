// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("chartkick/chart.js")
require('moment')
require("dashboards/dashboard_topbar")
require("subscriptions/campaign_opt_in")
require("subscriptions/system_opt_in")
require("subscriptions/meal_swaps")
require("kms_orders/kms_order")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/tooltip'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/tab'
import "controllers"
import 'core-js/stable'
import "bootstrap4-toggle/js/bootstrap4-toggle.min"
import "bootstrap-select/js/bootstrap-select.js"
import moment from 'moment'
import 'regenerator-runtime/runtime'
import 'select2/dist/css/select2.min';
import 'select2';
// import select2 from 'select2'

window.jQuery = $;
window.$ = $;
$(document).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on('turbolinks:load', function() {
  $('.select2').select2();
});

window.moment = moment
require('stylesheets/application.scss')

$(document).on('turbolinks:load', function() {
  $('.checkbox').bootstrapToggle();
});

$(document).on('turbolinks:load', function() {
  $('.selectpicker').selectpicker();
});
