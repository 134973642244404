const ACTION_CREATE = "create";
const ACTION_UPDATE = "update";

function populate_modal_from_partial(partial) {
  $('#kms-order-modal-content').html(partial);
  $('#kms-order-modal-div').modal('show');
}

$(document).on("turbolinks:load", function () {
  // toggle kms delivery details div with caret icon
  $('.kms-delivery-toggle').on("click", function (event) {
    let delivery_div = $(this).closest('.kms-delivery-div');
    let delivery_items_div = delivery_div.find('.kms-delivery-items-div');
    delivery_items_div.toggle('fast');

    if ($(this).hasClass('fa-angle-down')) {
      $(this).removeClass('fa-angle-down');
      $(this).addClass('fa-angle-up');
    } else {
      $(this).removeClass('fa-angle-up');
      $(this).addClass('fa-angle-down');
    }
  });

  // open modal for either editing & creating a new delivery item
  $('.kms-delivery-item-btn').on("click", function (event) {
    let action = $(this).data("action");
    if (action == ACTION_CREATE) {
      let delivery_id = $(this).data("kms-delivery-id");
      $.ajax({
        url: `/kms_delivery_items/new/`,
        type: "GET",
        data: {"kms_delivery_id": delivery_id},
        success: function (data) {
          populate_modal_from_partial(data);
        }
      });
    } else if (action == ACTION_UPDATE) {
      let delivery_item_id = $(this).data("kms-delivery-item-id");
      $.ajax({
        url: `/kms_delivery_items/${delivery_item_id}/`,
        type: "GET",
        success: function (data) {
          populate_modal_from_partial(data);
        }
      });
    }
  });

  // open modal for editing a delivery
  $('.kms-delivery-edit-btn').on("click", function (event) {
    let delivery_id = $(this).data("kms-delivery-id");
    $.ajax({
      url: `/kms_deliveries/${delivery_id}/`,
      type: "GET",
      success: function (data) {
        $('#kms-order-modal-content').html(data);
        $('.checkbox').bootstrapToggle();
        $('#kms-order-modal-div').modal('show');
      }
    });
  });

  // update deliverable_type based on currently selected deliverable
  $("body").on("change", ".kms-delivery-item-deliverable-id", function (event) {
    let item_type = $(this).find(":selected").data("item-type");
    let form = $(this).closest(".kms-delivery-item-form");
    let deliverable_type_input = form.find(".kms-delivery-item-deliverable-type");
    deliverable_type_input.val(item_type);
  });

  // update item options on meal day change
  $("body").on("change", ".kms-delivery-item-meal-day", function (event) {
    let form = $(this).closest(".kms-delivery-item-form");
    let item_input = form.find(".kms-delivery-item-deliverable-id");
    let selected_item = item_input.find(":selected");
    let selected_item_id = selected_item.val();
    let selected_item_type = selected_item.data("item-type");

    let delivery_id = form.find('[name="kms_delivery_item[kms_delivery_id]"]').val();
    let meal_date = $(this).val();
    let item_type_filter = selected_item_type;
    // any item type can be added for a net new item
    if (form.find("form").hasClass("new_kms_delivery_item")) {
      item_type_filter = null;
    }

    $.ajax({
      url: `/kms_deliveries/${delivery_id}/item_options`,
      type: "GET",
      data: { "meal_date": meal_date, "item_type": item_type_filter },
      success: function (data) {
        item_input.empty();
        data.forEach((item) => {
          let option = $('<option/>');
          option.val(item.id);
          option.text(`[${item.meal}] ${item.name}`);
          option.data("item-type", item.type);
          // keep current item selected if it's still an option
          if (item.type == selected_item_type && item.id == selected_item_id) {
            option.prop("selected", true);
          }
          item_input.append(option);
        });
      }
    });
  });
});
