$(document).on("turbolinks:load", function () {
    let timeout = null,
        searchBar = $("#addon_search");

    searchBar.on("input", function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            submitSearch()
        }, 500);
    });

    $("#addon_products_search_btn").on("click", function () {
        submitSearch();
    });

    let submitSearch = function () {
        $("#hidden_spinner").show();
        $.ajax({
            url: "/addon_products.js",
            type: "GET",
            data: {filter: searchBar.val()},
            complete: function () {
                $("#hidden_spinner").hide();
            }
        });
    };
});
