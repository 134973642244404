$(document).on("turbolinks:load", function () {
    let timeout = null,
        searchBar = $("#sold_out_search");

    searchBar.on("input", function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            submitSearch()
        }, 500);
    });

    $("#sold_out_products_search_btn").on("click", function () {
        submitSearch();
    });

    let submitSearch = function () {
        $("#hidden_spinner").show();
        $.ajax({
            url: "/sold_out_products.js",
            type: "GET",
            data: {filter: searchBar.val()},
            complete: function () {
                $("#hidden_spinner").hide();
            }
        });
    };

    $(document).ready(function () {
        $(".discount_code_search_select2").select2({
            placeholder: "Search",
            minimumInputLength: 3,
            ajax: {
                url: "/shopify_discount_codes.json",
                dataType: "json",
                delay: 250,
                quietMillis: 300,
                data: function (params) {
                    var query = {
                        search: params.term,
                        id_type: "code",
                    }
                    return query;
                }
            }
        });
    });
});
