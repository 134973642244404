$(document).on("turbolinks:load", function () {
    // update swap-to options to only valid swaps
    // meal cant be swapped with itself
    // meals can only be swapped with meals of the same cycle
    // breakfasts only allow breakfasts, lunch / dinners only allow lunch / dinners
    $('.meal-swap-select.swap-from').on("change", function (event) {
        let selected_option = $(this).find(':selected');
        let swap_div = $(this).closest('.meal-swap-div');
        let swap_to_select = swap_div.find('.meal-swap-select.swap-to');

        let meal = $(this).val();
        let prev_meal = $(this).data('previous');

        // dont allow this swap_from to be used for another swap, but allow previous value
        let swaps_container = $(this).closest('.meal-swaps-container');
        swaps_container.find('.meal-swap-select.swap-from option').each(function() {
            let other_meal = $(this).val();
            if (meal.length > 0 && other_meal == meal) {
                $(this).hide();
            }
            else if (other_meal == prev_meal) {
                $(this).show();
            }
        });
        $(this).data('previous', meal);

        if (meal.length > 0) {
            let cycle = selected_option.data('cycle');

            // if the recipe exists, then the option will have a data-meal attr with the meal type
            // else, the value will be the meal code (eg. B1, L2, D3) that we can get the type from
            let meal_type = '';
            if ($(this).data('meal')) {
                meal_type = $(this).data('meal')[0];
            }
            else {
                meal_type =  meal.toLowerCase()[0];
            }

            let allowed_meals = [meal_type];
            if (meal_type == "l" || meal_type == "d") {
                allowed_meals = ["l", "d"];
            }

            swap_to_select.children('option').each(function () {
                if ($(this).val().length > 0) {
                    let option_meal = $(this).val();
                    let option_cycle = $(this).data('cycle');
                    let is_same_cycle = (option_cycle == cycle);

                    let option_meal_type = '';
                    if ($(this).data('meal')) {
                        option_meal_type = $(this).data('meal')[0];
                    }
                    else {
                        option_meal_type =  option_meal.toLowerCase()[0];
                    }

                    if (option_meal != meal && is_same_cycle && allowed_meals.includes(option_meal_type)) {
                        $(this).show();
                    } else {
                        // reset value of swap-to if it's currently something that would be invalid
                        if ($(this).is(":selected")) {
                            swap_to_select.val('');
                        }
                        $(this).hide();
                    }
                }
            });
        } else {
            swap_to_select.children('option').each(function () {
                $(this).show();
            });
        }
    });

    $('.meal-swap-select.swap-from').each(function () {
        $(this).trigger('change');
    });
});