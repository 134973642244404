import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  connect() {
    const id = this.data.get('id');
    const tooltipTitles = JSON.parse(this.data.get('titles'));
    const chart = Chartkick.charts[id];
    if(chart == null) {
      return
    }
    const newOptions = {
      library: {
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              const index = tooltipItem[0].index;
              return tooltipTitles[index]
            }
          }
        }
      }
    };
    const mergedOptions = $.extend(chart.options,newOptions);
    chart.setOptions(mergedOptions);
  }

  toggle_visualizer(event) {
    event.preventDefault()
    const graph = document.getElementById(this.data.get('graphId'));
    const table = document.getElementById(this.data.get('tableId'));
    if(graph.style.display == 'none') {
      graph.style.display = 'block';
      table.style.display = 'none';
      event.target.innerHTML = 'Show Table';
    } else {
      graph.style.display = 'none';
      table.style.display = 'block';
      event.target.innerHTML = 'Show Chart';
    }
  }
}
