$(document).on("turbolinks:load", function () {
    let timeout = null,
        searchBar = $("#customer_search_bar"),
        searchBy = $("#search_by"),
        typeSelect = $("#sub_type_search"),
        resourceTypeSelect = $("#resource_type_filter"),
        customerId = $("#customer_id");

    searchBar.on("input", function () {
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            submitSearch()
        }, 500);
    });

    typeSelect.on("change", function () {
        console.log(typeSelect.val());
        submitSearch();
    });

    $("customer_search_btn").on("click", function () {
        submitSearch();
    });

    let submitSearch = function () {
        $("#hidden_spinner").show();
        $.ajax({
            url: "/customers.js",
            type: "GET",
            data: {filter: searchBar.val(), sub_type: typeSelect.val(), search_by: searchBy.val()},
            complete: function () {
                $("#hidden_spinner").hide();
            }
        });
    };

    $("#add-new-customer-tag-button").on("click", function () {
        let newTagInput = $("#add-new-customer-tag-input");
        if(newTagInput.val()) {
            let newDiv = $("<div>", {class: "form-check"});
            newDiv.append($('<input/>').attr({
                type: "checkbox",
                value: newTagInput.val(),
                class: "form-check-input",
                multiple: true,
                checked: "checked",
                name: "customer[tags][]"
            })).append($('<label/>').attr({
                class: "form-check-label",
            }).text(newTagInput.val()))
            $('#customer-tags-checkbox').append(newDiv);
        }
        newTagInput.val('');
    });

    $(document).on("change", "#resource_type_filter", function () {
        getResources();
    });

    let getResources = function () {
        $("#hidden_spinner").show();
        $.ajax({
            url: "/customers/" + $("#customer_id").val() + ".js",
            type: "GET",
            data: {resource_type: resourceTypeSelect.val()},
            complete: function () {
                $("#hidden_spinner").hide();
            }
        });
    };

    $(document).on("click", "#start_date", function (event) {
        event.preventDefault();
        customerId = $(this).data("customer-id");
        column = $(this).data("column")
        direction = $(this).data("direction");
        resourceType = $("#resource_type_filter").val();
        sortedResources(customerId, resourceType, column, direction);
    });

    let sortedResources = function (customerId, resourceType, column, direction) {
        $("#hidden_spinner").show();
        $.ajax({
            url: "/customers/" + customerId + ".js?sort=" + column + "&direction=" + direction + "&resource_type=" + resourceType,
            type: "GET",
            data: {resource_type: resourceTypeSelect.val()},
            complete: function () {
                if(direction === "asc") {
                  $("#start_date").attr("data-direction", "desc");
                  $("#start_date").removeClass("fa-caret-up");
                  $("#start_date").addClass("fa-caret-down");
                } else {
                  $("#start_date").attr("data-direction", "asc");
                  $("#start_date").removeClass("fa-caret-down");
                  $("#start_date").addClass("fa-caret-up");
                }
                $("#hidden_spinner").hide();
            }
        });
    };
});
