$(document).on("turbolinks:load", function () {
    $('.recipe-menu-param-select').on('change', function() {
        let menu_id = $('#menu_select').val();
        let kitchen_id = $('#kitchen_select').val();
        let start_date = $('#start_date_select').val();

        let new_url = `/recipe_menus/${menu_id}?kitchen_id=${kitchen_id}&start_date=${start_date}`;
        window.location.href = new_url;
    });
});
