import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["add_item", "template"]

  add_association(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/WEEK_SKU_TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)

    const nestedFormClass = '.' + event.currentTarget.dataset.nestedFormClass
    let nestedFields = Array.from(document.querySelectorAll(nestedFormClass)).filter(element => element.querySelector("input[name*='_destroy']").value != 1)
    const lastNestedField = nestedFields[nestedFields.length - 2]
    let lastWeek = 1

    if (lastNestedField) {
      let lastValue = lastNestedField.children[0].children[2].value
      lastWeek = parseInt(lastValue) + 1
    }

    nestedFields[nestedFields.length - 1].children[0].children[1].innerHTML = `Week ${lastWeek}`
    nestedFields[nestedFields.length - 1].children[0].children[2].value = lastWeek
    nestedFields[nestedFields.length - 1].id += lastWeek
  }

  remove_association(event) {
    event.preventDefault()
    let nestedFormClass = '.' + event.currentTarget.dataset.nestedFormClass
    let item = event.target.closest(nestedFormClass)
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
