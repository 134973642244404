$(document).on("turbolinks:load", function () {

    $("#create_refund_button").on("click", function (e) {
        let billing_attempt_id = this.getAttribute("data-billing-attempt-id");
        let subscription_id = this.getAttribute("data-subscription-id");
        $("#hidden_spinner_for_refund").show();
        $.ajax({
            url: "/billing_attempts/"+billing_attempt_id+"/refunds.js?subscription_id="+subscription_id,
            type: "POST",
            data: {amount: $("#amount").val(), reason: $("#reason").val(), refund_method: $("#refund_method").val()},
            complete: function () {
                $("#hidden_spinner_for_refund").hide();
            }
        });
        e.preventDefault();
    });

    $("#reset_date_range_btn").click(function() {
        $("#selected_date_range").flatpickr().clear();
    })
});
