import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["add_item", "template"]

  add_association(event) {
    event.preventDefault()

    let content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let nestedFormClass = '.'+event.currentTarget.dataset.nestedFormClass
    let item = event.target.closest(nestedFormClass)
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
