$(document).on("turbolinks:load", function () {
  $('.recipe-setting-color-picker').on("change", function () {
    let example_text = $('#recipe-setting-new-meal-example');
    let css_rule = $(this).data("css-rule");
    let value = $(this).val();
    example_text.css(css_rule, value);
  });

  $('.recipe-setting-meal-copy').on("change", function () {
    let example_text = $('#recipe-setting-new-meal-example');
    let value = $(this).val();
    example_text.text(value);
  });

  $('.recipe-setting-meal-copy').change();
  $('.recipe-setting-color-picker').change();
});
