import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [
    'fetchingStartWeek', 'fetchingDetoxWeek' ,'startWeek', 'detoxWeek', 'soldOut'
  ]

  beforeReflex (element, reflex) {
    super.beforeReflex(element, reflex)
    if (reflex === 'CampaignReflex#update_start_week')
      this.fetchingStartWeekTarget.hidden = false
      this.fetchingDetoxWeekTarget.hidden = false
      this.startWeekTarget.hidden = true
      this.detoxWeekTarget.hidden = true
      this.soldOut.hidden = true
  }
}
