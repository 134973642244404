function refresh_attribute_options() {
    $('.select-export-rule-attribute').each(function () {
        let enforceUnique = $(this).data("unique");
        if (enforceUnique != true) {
          return;
        }

        // ensure each non-empty key option is selected only once
        let val = $(this).val();
        let prev = $(this).data('previous');

        $('.select-export-rule-attribute').not(this).each(function () {
            $(this).find('option').each(function () {
                if ($(this).val() == val && val.length > 0)
                    $(this).hide();
                else if ($(this).val() == prev)
                    $(this).show();
            });
        });
    });
}

function set_key_group(key_select) {
    let container = key_select.closest('.div-export-rule');
    let key_group = key_select.find(":selected").closest('optgroup').attr('label');
    container.find('.export-rule-key-group').val(key_group);
}

$(document).on('turbolinks:load', function () {
    $('.select-export-rule-attribute').each(function () {
        $(this).data('previous', $(this).val());
        set_key_group($(this));
    });

    $('body').on('change', '.select-export-rule-attribute', function () {
        refresh_attribute_options();
        set_key_group($(this));
        $(this).data('previous', $(this).val());
    });

    $('body').on('click', '.delete-export-rule', function () {
        let container = $(this).closest('.div-export-rule');
        container.fadeOut(500, () => {
            let key_select = container.find('.select-export-rule-attribute');
            key_select.val("");
            refresh_attribute_options();

            container.remove();
        });
    });

    $('#btn_add_export_rule').on('click', function () {
        let controller = $(this).data("controller");
        $.ajax({
            url: `/${controller}/new_export_rule.js`,
            type: "GET",
            dataType: "html",
            success: function (html) {
                $('#div_export_rules_container').append(html);
                refresh_attribute_options();
            },
            error: function (e1) {
                console.log(e1);
            }
        });
    });

    refresh_attribute_options();
});
