let ajax_queue = []

$(document).on("turbolinks:load", function () {
    // queue requests to prevent an early request returning after a later one
    $('#zip_code_search_bar').on("input", function (event) {
        let queue_length = ajax_queue.length;
        ajax_queue.push({url: '/delivery_management.js', type: 'GET', data: {filter: event.target.value}});
        if (queue_length == 0) {
            run_ajax_queue();
        }
    });
});

function run_ajax_queue() {
    if (ajax_queue.length > 0) {
        let ajax = ajax_queue[0];
        $.ajax({
            url: ajax['url'],
            type: ajax['type'],
            data: ajax['data'],
            success: function () {
                ajax_queue.shift();
                run_ajax_queue();
            },
            error: function () {
                ajax_queue.shift();
                run_ajax_queue();
            }
        });
    }
}