$(document).on("turbolinks:load", function () {
  $(document).ready(function () {
    $(".search_select2").select2({
      placeholder: "Search",
      minimumInputLength: 3,
      ajax: {
        url: "/customers/search",
        dataType: "json",
        delay: 250,
        quietMillis: 300,
        data: function (params) {
          var query = {
            search: params.term
          }
          return query;
        }
      }
    });
  });
});
