$(document).ready(function () {
  let addon_select = $(".addon_select2");
  addon_select.select2({
    placeholder: "Select an addon product",
  });

  addon_select.on("change", function () {
    submitSearch();
  });

  let submitSearch = function () {
    $("#hidden_spinner").show();
    $.ajax({
      url: "/addon_management.js",
      type: "GET",
      data: {filter: addon_select.val()},
      complete: function () {
        $("#hidden_spinner").hide();
      }
    });
  };
});
