$(document).on("turbolinks:load", function () {

    $("#create_test_sub_order_button").on("click", function (e) {
        let sub_id = this.getAttribute("data-subscription-id");
        console.log(sub_id);
        console.log($("#hidden_spinner").show())
        $("#hidden_spinner").show();
        $.ajax({
            url: "/subscriptions/"+sub_id+"/subscription_orders.js",
            type: "POST",
            data: {order_week: $("#order_week").val()},
            complete: function () {
                $("#hidden_spinner").hide();
            }
        });
        e.preventDefault();
    });
});