$(document).on("turbolinks:load", function () {
  let customerSelect = $('.product-order-create-form .customer');

  $('.customers-show .btn-success[data-target="#product_order_modal"]').on('click', function() {
    customerSelect.trigger('change');
    customerSelect.attr('disabled', 'disabled');
  });

  // populate name, email and address presets on customer select
  customerSelect.on('change', function() {
    let form = $(this).closest('.product-order-create-form');
    let customer_id = $(this).val();

    if (customer_id) {
      $.ajax({
        url: `/customers/${customer_id}/addresses.json`,
        type: "GET",
        success: function (customer_data) {
          let customer = customer_data.customer
          form.find('.email').val(customer.email);
          form.find('.first-name').val(customer.first_name);
          form.find('.last-name').val(customer.last_name);

          let address_field = form.find('.kms-shipping-address-preset');
          let blank = $('<option value="N/A">N/A</option>');
          address_field.empty();
          blank.appendTo(address_field);

          customer_data.addresses.forEach(a => {
            let opt = $('<option/>');
            opt.attr("value", a.id);
            opt.text(a.name);
            opt.appendTo(address_field);
          });
          address_field.closest('.kms-shipping-address-preset-div').show();
        }
      });
    }
  })
});
