$(document).on("turbolinks:load", function () {
  let timeout = null,
      searchBar = $("#taste_it_search");

  searchBar.on("input", function () {
      clearTimeout(timeout);
      timeout = setTimeout(function () {
          submitSearch()
      }, 500);
  });

  $("#taste_it_products_search_btn").on("click", function () {
      submitSearch();
  });

  let submitSearch = function () {
      $("#hidden_spinner").show();
      $.ajax({
          url: "/taste_it_products.js",
          type: "GET",
          data: {filter: searchBar.val()},
          complete: function () {
              $("#hidden_spinner").hide();
          }
      });
  };
});